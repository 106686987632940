import {Box, Card, CardContent, CardHeader, CircularProgress} from "@mui/material";
import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";
import {Bar, BarChart, ResponsiveContainer, XAxis, YAxis} from "recharts";
import {useTheme} from "@mui/material/styles";

function Summary(props) {
    let [currentWeather, setCurrentWeather] = React.useState(null);
    let [currentRekuperace, setCurrentRekuperace] = React.useState(null);
    let [currentPergola, setCurrentPergola] = React.useState(null);

    if (currentWeather === null) {
        setCurrentWeather(false);
        props.api.getCurrentWeather().then(res=>{
            if (res.status === 'ok') {
                setCurrentWeather(res.data)
            }
        })
    }
    if (currentRekuperace === null) {
        setCurrentRekuperace(false)
        props.api.getRekuperaceStatus().then(res=>{setCurrentRekuperace(res.data.rekuperace)})
    }
    if (currentPergola === null) {
        setCurrentPergola(false);
        props.api.getPergolaState().then(res=>{setCurrentPergola(res.state)})
    }

    return(
        <Grid container spacing={2}>
            {currentWeather?<CurrentWeather data={currentWeather} />:<Grid><CircularProgress /></Grid>}
            {currentRekuperace?<Grid md={3} xs={12}><Card><CardHeader title={'Rekuperace'} /><CardContent  sx={{width: 280}}><Typography variant="h6" color="textPrimary">{currentRekuperace}</Typography></CardContent></Card></Grid>:<Grid><CircularProgress /></Grid>}
            {currentPergola?<Grid md={3} xs={12}><Card><CardHeader title={'Pergola'} /><CardContent  sx={{width: 280}}><Typography variant="h6" color="textPrimary">{currentPergola}</Typography></CardContent></Card></Grid>:<Grid><CircularProgress /></Grid>}
        </Grid>
    )
}


function CurrentWeather(props) {
    const theme = useTheme();
    let t = new Date( props.data.weather.sunrise * 1000 );
    let sunrise = t.getHours().toString() + ":" + (t.getMinutes()<10?'0':'') + t.getMinutes();
    let t2 = new Date( props.data.weather.sunset * 1000 );
    let sunset = t2.getHours().toString() + ":" + (t2.getMinutes()<10?'0':'') + t2.getMinutes();


    let data = props.data.precipitation

    return (<Grid md={3} xs={12}>
                    <Card>
                    <CardContent>
                        <Box display="flex" flexDirection="row-reverse">
                            <Box sx={{marginTop: 2}} p={0}>
                                <Typography variant="h4" color="textPrimary">
                                    {props.data.weather.temp}
                                    <span>&#176;</span>
                                    {"C"}
                                </Typography>
                                <Typography color="textPrimary">
                                    Poc.: {props.data.weather.feels_like}
                                    <span>&#176;</span>
                                    {"C"}
                                </Typography>
                            </Box>
                            <Box sx={{marginRight: 5}}>
                                <img src={'http://openweathermap.org/img/wn/'+props.data.weather.weather[0].icon+'@2x.png'} />
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row-reverse">
                            <Box p={0}>
                                <Typography variant="h6" color="textSecondary">
                                    {props.data.weather.weather[0].description}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Box p={1}>
                                <Typography color="textPrimary">
                                    Vlhkost: {props.data.weather.humidity} %
                                </Typography>
                            </Box>
                            <Box p={1}>
                                <Typography color="textPrimary">
                                    Vítr: {props.data.weather.wind_speed} km/h
                                </Typography>
                            </Box>
                        </Box>
                        <ResponsiveContainer width="100%" height={80}>
                            <BarChart width={150} height={40} data={data}>
                                <Bar dataKey="precipitation" fill="#8884d8" />
                                <XAxis
                                    tickFormatter={(value)=>{
                                        let date = new Date(value);
                                        return date.getHours()+(date.getMinutes()<10?':0':':')+date.getMinutes();
                                    }}
                                    dataKey={"time"}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                        <Box display="flex" flexDirection="row">
                            <Box p={1}>
                                <Typography color="textPrimary">
                                    Východ: {sunrise}
                                </Typography>
                            </Box>
                            <Box p={1}>
                                <Typography color="textPrimary">
                                    Západ: {sunset}
                                </Typography>
                            </Box>
                        </Box>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default Summary;
