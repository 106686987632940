import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AirIcon from '@mui/icons-material/Air';
import RoofingIcon from '@mui/icons-material/Roofing';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import {Link} from "react-router-dom";

export const mainListItems = (
    <React.Fragment>
        <ListItemButton component={Link} to={"/"}>
            <ListItemIcon>
                <DashboardIcon/>
            </ListItemIcon>
            <ListItemText primary="Dashboard"/>
        </ListItemButton>
        <ListItemButton component={Link} to={"/rekuperace"}>
            <ListItemIcon>
                <AirIcon/>
            </ListItemIcon>
            <ListItemText primary="Rekuperace"/>
        </ListItemButton>
        <ListItemButton component={Link} to={"/pergola"}>
            <ListItemIcon>
                <RoofingIcon/>
            </ListItemIcon>
            <ListItemText primary="Pergola"/>
        </ListItemButton>
        <ListItemButton component={Link} to={"/pocasi"}>
            <ListItemIcon>
                <WbSunnyIcon/>
            </ListItemIcon>
            <ListItemText primary="Počasí"/>
        </ListItemButton>
    </React.Fragment>
);
