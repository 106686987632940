import {Box, Button, ButtonGroup, useMediaQuery} from "@mui/material";
import Title from "../Dashboard/Title";
import Paper from "@mui/material/Paper";

function Pergola(props) {
    const matches = useMediaQuery("(min-width:600px)");
    const orientation=matches?'horizontal':'vertical';

    return (
        <Paper
            sx={{
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                height: 600,
                textAlign: "center"
            }}
        >
            <Title>Pergola</Title>
            <Box  sx={{marginTop: 5}}>
            <ButtonGroup variant="contained"  orientation={orientation}>
                <Button onClick={()=>{
                    props.api.pergola("open")
                }}>Otevřít</Button>
                <Button onClick={()=>{
                    props.api.pergola("close")
                }}>Zavřít</Button>
            </ButtonGroup>
            </Box>
            <Box sx={{marginTop: 5}}>
            <ButtonGroup orientation={orientation} aria-label="medium secondary button group">
                <Button onClick={()=>{
                    props.api.pergola("open10")
                }}>Otevřít o 10%</Button>
                <Button onClick={()=>{
                    props.api.pergola("open20")
                }}>Otevřít o 20%</Button>
                <Button onClick={()=>{
                    props.api.pergola("close10")
                }}>Zavřít o 10%</Button>
                <Button onClick={()=>{
                    props.api.pergola("close20")
                }}>Zavřít o 20%</Button>
                <Button onClick={()=>{
                    props.api.pergola("flip")
                }}>Převrátit</Button>
            </ButtonGroup>
            </Box>
        </Paper>
    )
}


export default Pergola;
