import Paper from "@mui/material/Paper";
import Chart from "../Dashboard/Chart";
import {Box, CircularProgress} from "@mui/material";
import * as React from 'react';

function Weather(props) {
    let [data, setData] = React.useState(false);

    if (!data) {
        props.api.getTemperature('-1 day').then(res=>{
            if (res.status === 'ok') {
                setData(res.data);
            }
        })
    }

    return (
        <Paper
            sx={{
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                height: 600,
            }}
        >
            {data?<Chart data={data} />:<Box sx={{ marginLeft: 'auto',marginRight:'auto', mt: 5, display: 'flex' }}><CircularProgress /></Box>}
        </Paper>
    )
}

export default Weather;
