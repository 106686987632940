import * as React from 'react';
import {Box, Button, ButtonGroup, useMediaQuery} from "@mui/material";
import Title from "../Dashboard/Title";
import Paper from "@mui/material/Paper";


function Rekuperace(props) {
    const matches = useMediaQuery("(min-width:600px)");
    const orientation=matches?'horizontal':'vertical';
    let [rekuperace, setRekuperace] = React.useState("zjišťuji");

    if (rekuperace === "zjišťuji") {
        props.api.getRekuperaceStatus().then(res=>{setRekuperace(res.data.rekuperace)})
    }

    return (
        <Paper
            sx={{
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                height: 600,
                textAlign: "center"
            }}
        >
            <Title>Rekuperace</Title>
            <Box  sx={{marginTop: 5}}>
                Stav: {rekuperace}
            </Box>
            <Box  sx={{marginTop: 5}}>
            <ButtonGroup variant="contained"  orientation={orientation} aria-label="medium secondary button group">
                <Button onClick={()=>{
                    props.api.rekuperace("normal")
                    setRekuperace("Normální");
                }}>Normální</Button>
                <Button color={"error"} onClick={()=>{
                    props.api.rekuperace("off")
                    setRekuperace("Vypnuto");
                }}>Vypnuto</Button>
                <Button color={"success"} onClick={()=>{
                    props.api.rekuperace("high")
                    setRekuperace("Hodně");
                }}>Hodně</Button>
                </ButtonGroup>
        </Box>
        </Paper>
    )
}

export default Rekuperace;
