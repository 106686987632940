import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import {LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, Tooltip, CartesianGrid, Legend} from 'recharts';
import Title from './Title';

// Generate Sales Data
function createData(time, amount, feels, wind) {
    return { time, amount, feels, wind };
}

export default function Chart(props) {
    const theme = useTheme();
    let data = [];

    for (let time in props.data) {
        let temp = props.data[time].temp;
        let feels = props.data[time].feels;
        let wind = props.data[time].wind;
        let t = new Date( time * 1000 );
        let formatted = t.getHours().toString() + ":" +(t.getMinutes()<10?'0':'') + t.getMinutes()
        data.push(createData(formatted, temp, feels, wind));
    }

    return (
        <React.Fragment>
            <Title>Posledních 24h</Title>
            <ResponsiveContainer>
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 0,
                        right: 30,
                        left: 0,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="time"
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                    />
                    <YAxis
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                    >
                    </YAxis>
                    <Tooltip />
                    <Legend />
                    <Line
                        name="Skutečná"
                        isAnimationActive={true}
                        type="monotone"
                        dataKey="amount"
                        stroke={theme.palette.primary.main}
                        dot={false}
                    />
                    <Line
                        isAnimationActive={true}
                        type="monotone"
                        dataKey="feels"
                        name={"Pocitová"}
                        stroke={'#cc4000'}
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
